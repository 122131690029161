import React, { useRef, useState, useEffect } from 'react'
import { Modal, Form, Input, message, Select, Checkbox } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { updateProduct, getProduct, addProduct } from '../../services/product.service'
import { listProductTypes } from '../../services/productType.service'
import { listFacilities } from "../../services/facility.service"
import { listPatients } from '../../services/patient.service';
import MaskedInput from "antd-mask-input"
import moment from 'moment'
import ProductStatus from '../../enums/productStatus.enum'
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';
import Org from '../../enums/org.enum'
import ProductTypeCode from '../../enums/productTypeCode.enum'
import "./adminProductModal.scss"
import ProductHelper from '../../helpers/product.helper'

const { Item } = Form

const INACTIVE = [
    ProductTypeCode.CALCIFEDIOL,
    ProductTypeCode.VITAMIN_B12,
    ProductTypeCode.VITAMIN_B9_B12,
    ProductTypeCode.CONSULT_LONGEVITY_60,
    ProductTypeCode.CONSULT_HEART_HEALTH,
    ProductTypeCode.CONSULT_WEIGHT_MANAGEMENT,
    ProductTypeCode.CONSULT_METABOLIC_HEALTH,
    ProductTypeCode.CONSULT_METABOLIC_HEALTH_60,
    ProductTypeCode.CONSULT_LIPIDOLOGIST,
    ProductTypeCode.OMEGAQUANT_COMPLETE,
    ProductTypeCode.OMEGAQUANT_BASIC,
    ProductTypeCode.DEXA_BODYCOMP_REFERRAL,
    ProductTypeCode.DEXA_BONE_REFERRAL,
    ProductTypeCode.CTCALCIUM_REFERRAL,
    ProductTypeCode.HEART_HEALTH_LAB_VISIT, 
    ProductTypeCode.TOXIC_HAIR_TEST,
    ProductTypeCode.TRUAGE_PACE,
    ProductTypeCode.DEXA_BOTH,
  
]

export const AdminProductModal = ({ open, setOpen, onSuccess, productId, setProductId, patientId, select, populate }) => {
  const [form] = Form.useForm();
  const [facilities, setFacilities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [productTypes, setProductTypes] = useState([]);
  const [patients, setPatients] = useState([]);
  const [product, setProduct] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    fetchFacilities()
    fetchProductTypes()
    fetchPatients()
  }, []);

  const fetchFacilities = async () => {
    try {
      const facilities = await listFacilities();
      setFacilities(facilities || []);
    } catch (error) {
      console.error('Failed to fetch facilities:', error);
    }
  }

  // const fetchProductTypes = async () => {
  //   try {
  //     const productTypes = await listProductTypes();
  //     setProductTypes(productTypes || []);
  //   } catch (error) {
  //     console.error('Failed to fetch product types:', error);
  //   }
  // }
  const fetchProductTypes = async () => {
    try {
      const productTypes = await listProductTypes();
      const validProductTypes = productTypes.filter(type => 
        Object.values(ProductTypeCode).includes(type.code) && !INACTIVE.includes(type.code)
      ).sort((a, b) => a.title.localeCompare(b.title));;
      setProductTypes(validProductTypes || []);
    } catch (error) {
      console.error('Failed to fetch product types:', error);
    }
  };

  const sortPatients = (unsortedPatients) => {
    return unsortedPatients.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameb = `${b.firstName} ${b.lastName}`.toLowerCase();
  
      if (nameA < nameb) {
          return -1;
      }
      if (nameA > nameb) {
          return 1;
      }
      return 0;
    })
  }

  const fetchPatients = async () => {
    try {
      const patients = await listPatients({
        filter: {
          org: Org.INSTALAB,
          firstName: {
            $ne: null
          },
          email: {
            $ne: null
          }
        },
        select: '_id firstName lastName',
        populate: []
      });
      setPatients(sortPatients(patients));
    } catch (error) {
      console.error('Failed to fetch patients:', error);
    }
  }

  useEffect(() => {
    if (open) {
      if (productId) setProductFields()
    } else {
      form.resetFields()
      setProduct(null)
      setProductId(null)
      setHasAttempt(false)
    }
  }, [open])


  const onCancel = () => {
    setOpen(false)
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid product data')
  }

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      let values = form.getFieldsValue();

      // Regex to check for the placeholder format in the 'start' field
      const placeholderRegex = /^__\/__\/____ __:__ -__:__$/;

      // Check if the 'start' field is empty or contains placeholder values
      if (!values.start || placeholderRegex.test(values.start)) {
        values.start = null; // Set to null if no valid date is provided
      }

      if (!values.facility || values.facility === '')
        values.facility = null


      let productResponse
      if (productId) {
        // Update existing product
        productResponse = await updateProduct(productId, {
          fields: values,
          select,
          populate
        });
        message.success('Product updated successfully');
      } else {
        // Create new product
        values = {...values, free: true}
        if (patientId) values.patient = patientId
        const response = await addProduct({
          fields: values,
          select,
          populate
        });

        // is this a product where I need to create a task? 
        await ProductHelper.createTask(response.product)        

        message.success('Product added successfully');
        productResponse = response.product
      }

      if (onSuccess) {
        onSuccess(productResponse);
      }
      setOpen(false);
    } catch (error) {
      console.log('error', error)
      message.error('Failed to save product');
    } finally {
      setIsSubmitting(false);
    }
  }

  const setProductFields = async () => {
    const productFields = await getProduct(productId, {
      select,
      populate
    });
    setProduct(productFields)

    if (productFields.start) {
      const formattedStart = moment(productFields.start).format('MM/DD/YYYY HH:mm Z');
      productFields.start = formattedStart;
    }else {
      productFields.start = null; // Ensure null is handled correctly
    }

    form.setFieldsValue({
      ...productFields,
      facility: productFields.facility?._id, // Set the facility dropdown to match the product's facility
      productType: productFields.productType?._id, // Set the product type dropdown to match the product's type
      hasKit: !!productFields.hasKit, // Set the checkbox default value
      hasAppointment: !!productFields.hasAppointment, // Set the checkbox default value

    });
  }

  return (
    <Modal 
      open={open} 
      title={product ? `Update for ${product.patient.firstName} ${product.patient.lastName}` : "Add New Product"}
      onCancel={onCancel}
      okText='Save'
      footer={null}
      className="admin-product-modal"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        {!productId && !patientId && (
          <Item 
            label="Patient" 
            name="patient"
            rules={[
              RuleHelper.isRequired,
            ]}
            validateTrigger='onSubmit'
          >
            <Select 
              placeholder="Select patient..."
              options={patients?.map(patient => {
                return {
                  label: `${patient.firstName} ${patient.lastName}`,
                  value: patient._id
                }
              })}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              onSelect={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
              className="patient-select"
              showSearch
            />
          </Item>
        )}

        {product && <Item label="Product Type">{product.productType.title}</Item>}

        {!productId && (
          <Item 
            label="Product Type" 
            name="type"
            rules={[
              RuleHelper.isRequired,
            ]}
            validateTrigger='onSubmit'
          >
            <Select 
              placeholder="Select product type..."
              options={productTypes?.map(type => ({
                label: `${type.title} (${type.code})`,
                value: type.code
              }))}
              optionFilterProp="children"
              filterOption={(input, option) => 
                (option.label || '').replace(/\s/g, '').toLowerCase().indexOf((input || '').replace(/\s/g, '').toLowerCase()) >= 0
              }
              onSelect={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
              className="product-type-select"
              showSearch
            />
          </Item>
        )}

        {!productId &&  <Item 
          label={<>Price Override <span style={{ color: 'red', fontWeight: 'bold', marginLeft:5 }}>
          WILL CHARGE PATIENT'S CARD
        </span></>}
          name="priceOverride"
        >
          <Input placeholder="Price" type="text" />
        </Item>}

        {productId && <>
        <Item 
          label="Status" 
          name="status"
        >
          <Select 
            placeholder="Select status..."

            options={Object.values(ProductStatus).map(value => {
              return {
                label: `${value}`,
                value: `${value}`
              }
            })}
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            className="status-select"
            showSearch
          />
        </Item>

        <Item 
          label="Tracking #"
          name="trackingNumber"
        >
          <Input placeholder="Tracking" type="text" />
        </Item>

        <Item 
          label="Facility" 
          name="facility"
        >
          <Select 
            placeholder="Select facility..."
            options={[
              { label: "None", value: "" }, // Empty option
              ...facilities?.map(facility => ({
                label: `${facility.name}`,
                value: facility._id
              }))
            ]}
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            className="facility-select"
            showSearch
          />
        </Item>

        <Item 
          label="Appointment Time" 
          name="start">
            <MaskedInput
              placeholder={"MM/DD/YYYY HH:MM Z"}
              mask="00/00/0000 00:00 -00:00"
              inputMode="decimal"
              className="test-value-input"
            />
        </Item>

        <Item label="Has Kit" name="hasKit" valuePropName="checked">
          <Checkbox />
        </Item>

        <Item label="Has Appointment" name="hasAppointment" valuePropName="checked">
          <Checkbox />
        </Item></>}

        <ContinueButton
          text='Save'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}