import DISCOUNT from '../enums/discount.enum'
import { getProductTypeByCode } from '../services/productType.service'
import MembershipHelper from './membership.helper'
import ProductTypeCode from '../enums/productTypeCode.enum'
import { listTaskTemplates, addTask } from '../services/task.service'
import TaskCategory from '../enums/taskCategory.enum'

const ProductHelper = {
  getCost: async (code, longevityMembership, isProvider) => {
    const { cost } = await getProductTypeByCode(code)

    if (isProvider) {
      return Math.floor(cost*DISCOUNT)
    }
    return cost
  },
  getMemberPrice: (cost) => {
    if (!cost) return null

    return Math.floor(cost)
  },
  formatCost: (productType, hasDollarSign=true) => {
    if (!productType?.cost) return ''
    let { cost } = productType
    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  formatPrice: (price, hasDollarSign=true) => {
    if (!price) return ''
    const hasDecimal = price - Math.round(price) !== 0
    if (hasDecimal) {
      price = price.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      price = `$${price.toLocaleString()}`
    }
    return price
  },
  formatDiscountCost: (productType, longevityMembership, isProvider, hasDollarSign=true) => {
    if (!productType?.cost) return '';
  
    // Calculate the discounted cost
    let cost = productType.cost; //Math.floor(productType.cost * DISCOUNT);

    if (MembershipHelper.isUltimate(longevityMembership) && [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.CONSULT_LONGEVITY, ProductTypeCode.CONSULT_LONGEVITY_60, ProductTypeCode.LONGEVITY_PANEL].includes(productType.code)) {
      cost = 0
    }

    else if (isProvider) {
      cost = Math.floor(productType.cost * DISCOUNT)
    }
  
    if (hasDollarSign) {
      cost = `$${cost}`;
    }
    
    return cost;
  },
  createTask: async (product) => {

    // create task for when admin adds product
    // note: when patient purchases product, this is triggered on the completeflow flow.controller
    const { patient, productType } = product
    let taskCategory;

    switch (productType.code) {
      case ProductTypeCode.CTCALCIUM:
        taskCategory = TaskCategory.CORONARY_CALCIUM_SCAN
        break;
      case ProductTypeCode.CLEERLY:
        taskCategory = TaskCategory.CCTA
        break;    
      case ProductTypeCode.GALLERI:
        taskCategory = TaskCategory.GALLERI
        break;
      case ProductTypeCode.TOXIC_METALS_URINE_TEST:
        taskCategory = TaskCategory.HEAVY_METALS_TEST
        break;
      case ProductTypeCode.MYCOTOX_PROFILE:
        taskCategory = TaskCategory.MOLD_TOXINS
        break;
      case ProductTypeCode.WATCHPAT_ONE:
        taskCategory = TaskCategory.SLEEP_APNEA_TEST
        break;
      case ProductTypeCode.DIURNAL_CORTISOL_PROFILE:
        taskCategory = TaskCategory.DIURNAL_CORTISOL_PROFILE
        break;
      default:
        break;
    }

    if (taskCategory) {
      let params = {
        select: '',
        filter: {
          category: taskCategory,
          isActive: true,
          isDefault: true
        },
      }
  
      const taskTemplates = await listTaskTemplates(params)
  
      for (const taskTemplate of taskTemplates) {
        const params = {      
          patient,
          assignee: taskTemplate.assignee,
          title: taskTemplate.title,
          subtasks: taskTemplate.subtasks,
          description: taskTemplate.description,
          category: taskTemplate.category,
          timeEstimate: taskTemplate.timeEstimate
        }
        return await addTask(params)
        
      }
  
    }


    return 
    

  },
}

export default ProductHelper
